.dashboard {
  display: flex;
  pointer-events: none;
}

.cta {
  position: absolute;
  padding: 16px;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 99;
}

.cta-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #777;
  border-radius: 99px;
  padding: 8px 16px;
  font-weight: 600;
}

.cta-button .icon {
  margin-right: 8px;
}

.gradient {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  height: 40%;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  z-index: 5;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebarSigns {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  z-index: 10 !important;
}

.speed {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 16px;
  z-index: 10 !important;
}

.speed p {
  font-weight: 500;
  font-size: 200px;
  line-height: 1;
  letter-spacing: -8px;
}

.speed span {
  font-weight: 700;
  font-size: 24px;
  line-height: .1;
}

.speed div {
  font-weight: 500;
  font-size: 20px;
  line-height: 3;
  color: #ccc;
  pointer-events: all;
  cursor: pointer;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-attrib {
  display: none !important;
}

.UserMarker {
  width: 48px;
  height: 48px;
  background-image: url('data:image/svg+xml;utf8,<svg width="25" height="52" viewBox="0 0 25 52" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4417 0.190674L24.8227 51.3276H0.0606728L12.4417 0.190674Z" fill="white"/></svg>');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
